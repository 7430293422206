const navMenuDiv = document.getElementById("nav-content");
const navMenu = document.getElementById("nav-toggle");

const check = (event) => {
  let target = (event && event.target);

  if (!checkParent(target, navMenuDiv)) {
    if (checkParent(target, navMenu)) {
      if (navMenuDiv.classList.contains("hidden")) {
        navMenuDiv.classList.remove("hidden");
      } else { navMenuDiv.classList.add("hidden"); }
    } else {
      navMenuDiv.classList.add("hidden");
    }
  }
}

const checkParent = (target, el) => {
  while (target.parentNode) {
    if (target == el) { return true; }
    target = target.parentNode;
  }
  return false;
}

const init = () => {
  document.addEventListener('click', check)
}

init();
